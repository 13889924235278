// @flow

import initI18n from '@/packs/localisation'
import { initCookieConsent } from '../components/cookie_consent/index'
import addMobileNavHelperListeners from '../helpers/MobileNavHelper'

import * as Sentry from '@/utils/sentry'
import type { Language } from '@/packs/localisation'

const dataContainer = document.querySelector('#influencer-content-tag')
if (!dataContainer) {
  Sentry.captureException('Could not find #influencer-content-tag', {
    tags: {
      product: Sentry.Product.Influencer
    }
  })
} else {
  const { dataset } = dataContainer

  const initLanguage = (): void => {
    const { language } = dataset
    // eslint-disable-next-line flowtype/no-weak-types
    const preferredLanguage = ((language || 'en': any): Language)

    initI18n(preferredLanguage)
  }

  document.addEventListener('DOMContentLoaded', (): void => {
    initLanguage()
    addMobileNavHelperListeners()
    initCookieConsent()
  })

}
